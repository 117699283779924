import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import InfoTable from './infoTable';
function SubIndicatorsAccordion(props){
    return(
        <>
            <Accordion defaultActiveKey="0" onSelect={props.handleSelected} >
                {
                    props.data != null && props.data.map((subIndicator, index) => {
                        return(
                            <Accordion.Item eventKey={subIndicator.subIndicatorId} key={subIndicator.subIndicatorId}>
                                <Accordion.Header>{subIndicator.title}</Accordion.Header>
                                <Accordion.Body>
                                    <p>{subIndicator.description}</p>
                                    <InfoTable
                                        columns={props.tableColumns}
                                        data={props.tableData}
                                    />
                                    <InfoTable
                                        columns={props.quartersTableColumns}
                                        data={props.quartersTableData}
                                    />
                                    <b className='notes-footer'>Notas:</b><br/>
                                    <div className='notes-footer' dangerouslySetInnerHTML={{ __html: subIndicator.notes }} />
                                   
                                </Accordion.Body>
                            </Accordion.Item>
                        );
                    })
                }
            </Accordion>
        </>
    )
}

export default SubIndicatorsAccordion;