import DataTable from 'react-data-table-component';

function InfoTable(props){
    const customStyles = {
        rows: {
            style: {
                backgroundColor: '#e8e8e8',
            },
        },
        headCells: {
            style: {
                backgroundColor: '#e8e8e8',
            },
        }
    };
    return(
        <>
            <DataTable
                columns={props.columns}
                data={props.data}
                dense
                responsive
                customStyles={customStyles}
            />
        </>
    );
}




export default InfoTable;