import constants from "../utils/constants";
var retry = true;
const getGeneralIndicatorsCall = () => {
    
    let controller = new AbortController();
    console.log('================= getGeneralIndicatorsCall =================');
    setTimeout(()=>{
      console.log('abort conecction');
      controller.abort();
    }, constants.timeout);
    
    return fetch(`${constants.apiHost}/indicators/generalIndicators`, {
          method: 'GET',
          credentials: 'include',
          signal: controller.signal,
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
          }
      })
      .then((response) => {
        console.log({response})
        const statusCode = response.status;
        const data = response.status == 200 ? response.json() : '';
        if(response.status == 200) retry = true;
        return { statusCode, data };
      })
      .then((res) => {
        console.log('Second rest');
        const _data = res.data;
        console.log({res});
        console.log({_data});
        if(res.statusCode == 200){
            return _data;
        }else{
            return undefined;
        }
      })
      .catch((error) => {
        console.error('error executing getGeneralIndicatorsCall :: '+ error);
        throw error;
      });    
  }

  const getSubIndicatorsByGeneralIndicatorIdCall = (generalIndicatorId) => {
    let controller = new AbortController();
    console.log('================= getSubIndicatorsByGeneralIndicatorIdCall =================');
    setTimeout(()=>{
      console.log('abort conecction');
      controller.abort();
    }, constants.timeout);
    
    return fetch(`${constants.apiHost}/indicators/subIndicators/${generalIndicatorId}`, {
          method: 'GET',
          credentials: 'include',
          signal: controller.signal,
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
          }
      })
      .then((response) => {
        console.log({response})
        const statusCode = response.status;
        const data = response.status == 200 ? response.json() : '';
        return { statusCode, data };
      })
      .then((res) => {
        console.log('Second rest');
        const _data = res.data;
        console.log({res});
        console.log({_data});
        if(res.statusCode == 200){
            return _data;
        }else{
            return undefined;
        }
      })
      .catch((error) => {
        console.error('error executing getSubIndicatorsByGeneralIndicatorIdCall :: '+ error);
      });    
  }

  const getIndicatorsBySubIndicatorIdCall = (subIndicatorId, periodicityId, page) => {
    let controller = new AbortController();
    console.log('================= getIndicatorsBySubIndicatorIdCall =================');
    setTimeout(()=>{
      console.log('abort conecction');
      controller.abort();
    }, constants.timeout);
    
    return fetch(`${constants.apiHost}/indicators/paged/${subIndicatorId}/${periodicityId}/${page}`, {
          method: 'GET',
          credentials: 'include',
          signal: controller.signal,
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
          }
      })
      .then((response) => {
        console.log({response})
        const statusCode = response.status;
        const data = response.status == 200 ? response.json() : '';
        return { statusCode, data };
      })
      .then((res) => {
        console.log('Second rest');
        const _data = res.data;
        console.log({res});
        console.log({_data});
        if(res.statusCode == 200){
            return _data;
        }else{
            return undefined;
        }
      })
      .catch((error) => {
        console.error('error executing getIndicatorsBySubIndicatorIdCall :: '+ error);
      });    
  }

  const getIndicatorsDataChartByIndicatorIdAndYears = (indicatorId, years) => {
    let controller = new AbortController();
    console.log('================= getIndicatorsDataChartByIndicatorIdAndYears =================');
    setTimeout(()=>{
      console.log('abort conecction');
      controller.abort();
    }, constants.timeout);
    
    return fetch(`${constants.apiHost}/indicators/data/${indicatorId}/${years}`, {
          method: 'GET',
          credentials: 'include',
          signal: controller.signal,
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
          }
      })
      .then((response) => {
        console.log({response})
        const statusCode = response.status;
        const data = response.status == 200 ? response.json() : '';
        return { statusCode, data };
      })
      .then((res) => {
        console.log('Second rest');
        const _data = res.data;
        console.log({res});
        console.log({_data});
        if(res.statusCode == 200){
            return _data;
        }else{
            return undefined;
        }
      })
      .catch((error) => {
        console.error('error executing getIndicatorsDataChartByIndicatorIdAndYears :: '+ error);
      });    
  }


  const getUserInfo = () => {
    
    let controller = new AbortController();
    console.log('================= getUserInfo =================');
    setTimeout(()=>{
      console.log('abort conecction');
      controller.abort();
    }, constants.timeout);
    
    return fetch(`${constants.apiHost}/public/user/info`, {
          method: 'POST',
          credentials: 'include',
          signal: controller.signal,
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
          }
      })
      .then((response) => {
        console.log({response})
        const statusCode = response.status;
        const data = response.status == 200 ? response.json() : '';
        return { statusCode, data };
      })
      .then((res) => {
        console.log('Second rest');
        const _data = res.data;
        console.log({res});
        console.log({_data});
        if(res.statusCode == 200){
            return _data;
        }else{
            return undefined;
        }
      })
      .catch((error) => {
        console.error('error executing getUserInfo :: '+ error);
      });    
  }

  const logout = () => {
    let controller = new AbortController();
    console.log('================= logout =================');
    setTimeout(()=>{
      console.log('abort conecction');
      controller.abort();
    }, constants.timeout);
    
    return fetch(`${constants.apiHost}/logout`, {
          method: 'GET',
          credentials: 'include',
          signal: controller.signal,
          headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
          }
      })
      .then((response) => {
        console.log({response})
        const statusCode = response.status;
        const data = response.status == 200 ? response.json() : '';
        return { statusCode, data };
      })
      .then((res) => {
        console.log('Second rest');
        const _data = res.data;
        console.log({res});
        console.log({_data});
        if(res.statusCode == 200){
            return _data;
        }else{
            return undefined;
        }
      })
      .catch((error) => {
        console.error('error executing logout :: '+ error);
      }); 
  }


  export default { getGeneralIndicatorsCall, getSubIndicatorsByGeneralIndicatorIdCall,
    getIndicatorsBySubIndicatorIdCall, getIndicatorsDataChartByIndicatorIdAndYears,
    getUserInfo, logout };