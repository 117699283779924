import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

function Footer() {
    return (
        <Container className='footer' fluid>       
                <Container>
                    <Row className="inner_row">
                            <Col xs={6}>
                                <ul className="contact_info">
                                    <li><i className="material-icons"></i> Sibylla Delphica Estad&iacute;stica Aplicada, SC</li>
                                    <li><i className="material-icons">&#xE0C8;</i> Monterrey, NL</li>
                                    <li><i className="material-icons">&#xE0CD;</i> Tel&eacute;fono: (52) 8181 234895</li>
                                    <li><i className="material-icons">&#xE0BE;</i> Correo: <a href="mailto:atencion@delphica.mx">atencion@delphica.mx</a></li>
                                </ul>
                            </Col>
                            <Col xs={6} className='text-right'>
                                <p className="owner">Un desarrollo de <img  style={{'cursor' : 'pointer'}} onClick={()=> window.open("http://www.delphica.mx", "_blank")}  src={require('../images/logo-new.png')} draggable="false" /></p>

                                <nav class="social_icons">
                                    <a href="https://www.facebook.com/SDelphica/" target="blank"><img src={require('../images/facebook_white.png')} draggable="false" /></a>
                                    <a href="https://twitter.com/SDelphica" target="blank"><img src={require('../images/twitter_white.png')} draggable="false" /></a>
                                    <a href="https://www.linkedin.com/company/sdelphica/" target="blank"><img src={require('../images/linkedin_white.png')} draggable="false" /></a>
                                </nav>
                                <nav class="footer_links">
                                    <a target="_blank" href="https://sectorial.conie.mx/privacy.jsp">Aviso de Privacidad</a>
                                </nav>
                            </Col>
                    </Row>
                </Container>
        </Container>
    );
}


export default Footer;