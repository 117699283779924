import create from 'zustand';


export const portalStore = create( (set) => ({
    dataLine : {datasets: [], ids: []},
    setDataLine : (labels, dataSets, id) => set(
            (state) =>(
                {   dataLine : {
                    labels: labels,
                    datasets: [...state.dataLine.datasets, dataSets],
                    ids: [...state.dataLine.ids, id]
            }}
            )
            
        ),
    removeDataLine : () => set({dataLine : {datasets: [], ids: []}}),
}));