import create from 'zustand';


export const portalQuarterlyStore = create( (set) => ({
    dataLineQuarter : {datasets: [], ids: []},
    setDataLineQuarter : (labels, dataSets, id) => set(
            (state) =>(
                {   dataLineQuarter : {
                    labels: labels,
                    datasets: [...state.dataLineQuarter.datasets, dataSets],
                    ids: [...state.dataLineQuarter.ids, id]
            }}
            )
            
        ),
    removeDataLineQuarter : () => set({dataLineQuarter : {datasets: [], ids: []}}),
}));