import './App.css';
import Home from './public/home';
import Header from './public/header';
import Footer from './public/footer';
import 'material-icons/iconfont/material-icons.css';

function App() {
  return (
    <>
      <Header />  
      <Home >
      <Footer />
        </Home>
      
    </>
  );
}

export default App;
