import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import {useState, useEffect, useRef} from 'react';
import api from '../services/api';
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import constants from '../utils/constants';
function Header() {
    const [user, setUser] = useState();
    const [showLoginModal, setShowLoginModal] = useState(false);
    
    useEffect(() => {
        console.log('useEffect Header');
        getUserInfo();
    }, []);

    useEffect(() => {
        const handleMessage = (event) => {
          // Do something with the message received from the iframe
          console.log('Message received from iframe:', event.data);
          if(event.data == 'SUCCESS_LOGIN'){
            hideModal();
          }
        };
    
        // Add event listener for "message" events
        window.addEventListener('message', handleMessage);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('message', handleMessage);
        };
      }, []);

    const getUserInfo = async () => {
        const response = await api.getUserInfo();
        console.log('response USER INFO', response);
        if(response){
            if(response.responseCode == 0){
                setUser(response.obj);
            }else{
                setUser(undefined);
            }
        }else{
            setUser(undefined);
        }
        
    }

    const hideModal = () => {
        //getUserInfo();
        window.location.reload();
    }

    const logout = async () => {
        const response = await api.logout();
        console.log('response LOGOUT', response);
        //getUserInfo();
        window.location.reload();
    }

    return (
        <Container className='header' fluid>
            <Container>
                <Row className="inner_row">
                        <Col xs={6}>
                            <div className="text_logo">
                                <h1>Conie <sup>&reg;</sup></h1>
                            </div>
                        </Col>

                        {
                            !user
                            ?
                                <Col id="loginFormDiv" xs={6} className="text-right">
                                    <button onClick={()=>setShowLoginModal(true)} className="login_btn" data-toggle="modal" data-target="#login"><i className="material-icons">&#xE7FD;</i> Iniciar sesi&oacute;n</button>
                                </Col>
                            :
                                <Col id="userInfoDiv" xs={6} className="col-xs-6 user_options text-right">
                                        <Dropdown className="login_btn d-inline mx-2">
                                            <Dropdown.Toggle className='login_btn' id="dropdown-autoclose-true">
                                               <i class="material-icons">&#xE851;</i> {`${user.name} ${user.lastName}`}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={()=>logout()} href="#"><i class="material-icons">&#xE8AC;</i> Cerrar Sesi&oacute;n</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                </Col>
                        }
                        
                        
                        
                </Row>
            </Container>

            <Modal size="mg" show={showLoginModal} onHide={()=>{setShowLoginModal(false); hideModal();}}>
                <Modal.Header closeButton>
                
                </Modal.Header>
                <Modal.Body>
                    <iframe src={`${constants.apiHost}/login.jsp`} width="100%" height="660px" frameborder="0">

                    </iframe>
                </Modal.Body>
            </Modal>

        </Container>
    )
}

export default Header;