import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CurrencyFormat from 'react-currency-format';
import {useState, useEffect, useRef, Children} from 'react';
import api from '../services/api';
import MainTabIndicators from '../component/mainTabIndicators';
import SubIndicatorsAccordion from '../component/subIndicatorsAccordion';
import * as ReactTool from 'react-tooltip';


import {portalStore} from '../store/portalStore';
import {portalQuarterlyStore} from '../store/portalQuarterlyStore';
import format from "../utils/format";
import Loading from 'react-fullscreen-loading';

import constants from '../utils/constants';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
  

  function Home(props) {
    let [loading, setLoading] = useState(false);
    let [mainTabInfo, setMainTabInfo] = useState([]);
    let [subTabInfo, setSubTabInfo] = useState([]);
    let [subIndicatorId, setSubIndicatorId] = useState(0);
    let [page, setPage] = useState(-1);
    let [pageQuarter, setPageQuarter] = useState(-1);
    let [tableData, setTableData] = useState([]);
    let [quartersTableData, setQuartersTableData] = useState([]);
    let [monthlyLabes, setMonthlyLabels] = useState([]);
    let [quartersLabes, setQuartersLabels] = useState([]);

    const [labels, setLabel] = useState([]);
    const [labelsQuarter, setLabelQuarter] = useState([]);

    let [dataSet, setDataSet] = useState([]);
    const [newIndicator, setNewIndicator] = useState();
    const [newIndicatorQuaterly, setNewIndicatorQuaterly] = useState();

    let {dataLine, setDataLine, removeDataLine} = portalStore();/*useState({});*/
    let {dataLineQuarter, setDataLineQuarter, removeDataLineQuarter} = portalQuarterlyStore();
    const chart1Ref = useRef(null);
    const chart2Ref = useRef(null);

    const [yearsChart1, setYearsChart1] = useState(-1);
    const [yearsChart2, setYearsChart2] = useState(-1);

    const [showChart1, setShowChart1] = useState(false);
    const [showChart2, setShowChart2] = useState(false);

    const [showMaxIndicators, setShowMaxIndicators] = useState(false);
    const [showNoMoreIndicators, setShowNoMoreIndicators] = useState(false);


    let [chartOptions, setChartOptions] = useState({
        responsive: true,
        plugins: {
            legend: {
            position: 'top' ,
            },
            title: {
            display: true,
            text: 'Grafico de datos Mensuales',
            },
        },
        scales: {
            y: {
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function(value, index, ticks) {
                        return '$' + value;
                    }
                },
                title: {
                    //display: true,
                    //text: 'Y-axis Label',
                },
            }
        }
    });

    let [chartOptionsPercent, setChartOptionPercent] = useState({
        responsive: true,
        plugins: {
            legend: {
            position: 'top' ,
            },
            title: {
            display: true,
            text: 'Grafico de datos Trimestrales',
            },
        },
    });

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    function back() {
        return (
            <div>
                <a onClick={backInfo} href='#a'>
                    <span class="material-icons">
                        navigate_before
                    </span>
                </a>
            </div>
        );
    }

    function next() {
        return (
            <div>
                <a onClick={nextInfo} href='#a'>
                <span class="material-icons">
                    navigate_next
                </span>
                </a>
            </div>
        );
    }

    function backQuarter() {
        return (
            <div>
                <a onClick={backQuarterInfo} href='#a'>
                    <span class="material-icons">
                        navigate_before
                    </span>
                </a>
            </div>
        );
    }

    function nextQuarter() {
        return (
            <div>
                <a onClick={nextQuarterInfo} href='#a'>
                    <span class="material-icons">
                        navigate_next
                    </span>
                </a>
            </div>
        );
    }

    function nextQuarterInfo() {
        console.log('next');
        let newPage = pageQuarter - 1;
        if(newPage > -1){
            setPageQuarter(newPage);
        } else {
            setShowNoMoreIndicators(true);
        }
    }

    function backQuarterInfo() {
        console.log('back');
        console.log('quartersLabes', quartersLabes);
        if( quartersLabes != null && quartersLabes.length == 6){
            let newPage = pageQuarter + 1;
            setPageQuarter(newPage);
        } else if (quartersLabes == null || quartersLabes.length < 6){
            setShowNoMoreIndicators(true);
        }
    }
    

    function nextInfo() {
        console.log('next');
        let newPage = page - 1;
        if(newPage > -1){
            setPage(newPage);
        } else {
            setShowNoMoreIndicators(true);
        }
        
    }

    function backInfo() {
        console.log('back');
        console.log('monthlyLabes.length', monthlyLabes?.length)
        if( monthlyLabes != null && monthlyLabes.length >= 6){
            console.log('monthlyLabes.length', monthlyLabes.length)
            let newPage = page + 1;
            setPage(newPage);
        } else if (monthlyLabes == null || monthlyLabes.length < 6){
            setShowNoMoreIndicators(true);
        }
    }

    const handleSelectGeneralIndicator = (eventKey) => {
        console.log(`selected ${eventKey}`);
        getSubIndicatorsByGeneralIndicator(eventKey);
    };
    
    const addToChart = async (indicatorId, force, _index) => {
        console.log('addToChart', indicatorId);
        console.log('dataLine.ids', dataLine.ids);
        console.log('force', force);
        console.log('dataLine.ids.includes(indicatorId)', dataLine.ids.includes(indicatorId));
        if (force || dataLine.ids.length < constants.monthlyColors.length) {
            if (force || (indicatorId != -1 && !dataLine.ids.includes(indicatorId))) {
                const response = await api.getIndicatorsDataChartByIndicatorIdAndYears(indicatorId, yearsChart1 == -1 ? 5 : yearsChart1);
                console.log('response', response);
                if (response && response.responseCode === 0) {
                    console.log('response.obj', response.obj)
                    let data = response.obj;
                    let _data = [];
                    let name = '';
                    let _labels = [];
                    let unit = '';
                    let source = '';
                    data.forEach(element => {
                        console.log(element);
                        name = element.indicator.shortName;
                        unit = element.indicator.unit;
                        source = element.indicator.source;
                        _data.push(element.value);
                        _labels.push(`${format.monthToString(element.month)} - ${element.year}`);
                    });
                    //TODO tener un rango de colores validos
                    let newChartItem = {
                        label: `${name}`,
                        unit: unit,
                        source: source,
                        data: [..._data],
                        borderColor: force ? constants.monthlyColors[_index][0] : constants.monthlyColors[dataLine.ids.length][0],
                        backgroundColor: force ? constants.monthlyColors[_index][1] : constants.monthlyColors[dataLine.ids.length][1],
                    }
                    /*let aa2 = {
                        label: name,
                        data: [..._data],
                        borderColor: `rgb(${Math. floor((Math. random() * 255) + 1)}, ${Math. floor((Math. random() * 255) + 1)}, ${Math. floor((Math. random() * 255) + 1)})`,
                        backgroundColor: `rgba(${Math. floor((Math. random() * 255) + 1)}, ${Math. floor((Math. random() * 255) + 1)}, ${Math. floor((Math. random() * 255) + 1)}, 0.5)`,
                    }*/

                    console.log('prev');
                    console.log('dataSet --->', dataSet);
                    console.log('after');
                    //setDataSet([...dataSet, aa]);
                    setChartOptions({
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: 'Grafico de datos Mensuales',
                            },
                            tooltip: {
                                callbacks: {
                                  footer: function(context)
                                    {
                                        console.log('FOOTER');
                                        console.log({context});
                                        return `Unidad: ${context[0].dataset.unit}, fuente: ${context[0].dataset.source}`
                                    },
                                }
                              }
                        },
                        scales: {
                            y: {
                                beginAtZero: true,
                                title: {
                                    display: true,
                                    text: dataLine.ids.length > 0 ? '' : unit,
                                },
                            }
                        }
                    })

                    setDataLine(_labels,
                        newChartItem, indicatorId);
                }
            } else {
                console.log('Indicador id is already in the chart');
            }
        } else {
            setShowMaxIndicators(true);
            console.log('No more monthly indicators can be added to the chart');
        }
        chart1Ref.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
    } 
        
    const addToChartQuaterly = async (indicatorId, force, _index) => {
        console.log('addToChartQuaterly', indicatorId);
        console.log('dataLineQuarter.ids', dataLineQuarter.ids);
        console.log('dataLine.ids.includes(indicatorId)', dataLineQuarter.ids.includes(indicatorId));
        if (force || dataLineQuarter.ids.length < constants.quarterlyColors.length){
            if (force || (indicatorId != -1 && !dataLineQuarter.ids.includes(indicatorId))) {
                const response = await api.getIndicatorsDataChartByIndicatorIdAndYears(indicatorId, yearsChart2 == -1 ? 5 : yearsChart2);
                console.log('response', response);
                if (response && response.responseCode === 0) {
                    console.log('response.obj', response.obj)
                    let data = response.obj;
                    let _data = [];
                    let name = '';
                    let unit = '';
                    let source = '';
                    let _labels = [];
                    data.forEach(element => {
                        console.log(element);
                        name = element.indicator.name;
                        unit = element.indicator.unit;
                        source = element.indicator.source;
                        _data.push(element.value);
                        _labels.push(`${element.year}T${element.quarter}`);
                    });
                    //TODO tener un rango de colores validos
                    let newChartItem = {
                        label: name,
                        unit : unit,
                        source : source,
                        data: [..._data],
                        borderColor: force ? constants.quarterlyColors[_index][0] : constants.quarterlyColors[dataLineQuarter.ids.length][0],
                        backgroundColor: force ? constants.quarterlyColors[_index][1] : constants.quarterlyColors[dataLineQuarter.ids.length][1],
                    }
                    /*let aa2 = {
                        label: name,
                        data: [..._data],
                        borderColor: `rgb(${Math. floor((Math. random() * 255) + 1)}, ${Math. floor((Math. random() * 255) + 1)}, ${Math. floor((Math. random() * 255) + 1)})`,
                        backgroundColor: `rgba(${Math. floor((Math. random() * 255) + 1)}, ${Math. floor((Math. random() * 255) + 1)}, ${Math. floor((Math. random() * 255) + 1)}, 0.5)`,
                    }*/

                    setChartOptionPercent({
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: 'Grafico de datos Trimestrales',
                            },
                            tooltip: {
                                callbacks: {
                                    footer: function(context)
                                    {
                                        return `Unidad: ${context[0].dataset.unit}, fuente: ${context[0].dataset.source}`
                                    }
                                }
                              }
                        },
                        scales: {
                            y: {
                                beginAtZero: true,
                                title: {
                                    display: true,
                                    text: dataLineQuarter.ids.length > 0 ? '' : unit,
                                },
                            }
                        }
                    })

                    console.log('prev');
                    console.log('dataSet --->', dataSet);
                    console.log('after');
                    //setDataSet([...dataSet, aa]);

                    setDataLineQuarter(_labels,
                        newChartItem, indicatorId);
                }
            } else {
                console.log('Indicador id is already in the chart');
            }
        } else {
            setShowMaxIndicators(true);
            console.log('No more quarterly indicators can be added to the chart');
        }
        chart2Ref.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
    } 

    /*useEffect(() => {
        console.log('useEffect DataLine?');
        //getGeneralIndicators();
    }, [dataLine]);
*/
    useEffect(() => {
        console.log('useEffect Home');
        console.log('window.navigator.language', window.navigator.language)
        getGeneralIndicators();
        setTimeout(() => {
            handleSelectGeneralIndicator(1);
        }, 500);
    }, []);
    
    useEffect(() => {
        console.log('useEffect NewIndicator');
        //getGeneralIndicators();
        addToChart(newIndicator);
    }, [newIndicator]);

    useEffect(() => {
        console.log('useEffect newIndicatorQuaterly');
        //getGeneralIndicators();
        addToChartQuaterly(newIndicatorQuaterly);
    }, [newIndicatorQuaterly]);

    useEffect(() => {
        console.log('useEffect Page');
        console.log('page', page);
        getIndicatorsBySubIndicator();
        
    }, [page, pageQuarter, subIndicatorId]);

    useEffect(() => {
        console.log('useEffect pageQuarter');
        console.log('page', pageQuarter);
        getIndicatorsBySubIndicatorByQuarters();
    }, [pageQuarter, subIndicatorId]);

    useEffect(() => {
        changeChar1tDataToYears(yearsChart1);
    }, [yearsChart1]);

    useEffect(() => {
        changeChar2tDataToYears(yearsChart2);
    }, [yearsChart2]);

    useEffect(() => {
        setLabel([
            {
                name: '',
                selector: row => 
                    row.key != "" && row.visible
                        ? 
                            <a href='#' onClick={()=>{
                                console.log('add C1 :: ' + row.indicatorId);
                                chart1Ref.current?.scrollIntoView({behavior: 'smooth', block: 'center'});
                                setNewIndicator(row.indicatorId);
                                setShowChart1(true);
                            }}>
                                <i class="material-icons">add_chart</i>
                            </a> 
                        : 
                            "",
                width:  '60px',
            },
        {
            name: '',
            selector: row => row.key,
            width:  '100px'
        },
        {
            name: <b>Mensuales</b>,
            width:  '394px',
            selector: row => row.key == "" ? '' : 
            <div style={{cursor: 'pointer'}} id={'i'+row.indicatorId}>{row.name}<br/><div className='table-unit'>{row.unit}</div>
            <ReactTool.Tooltip anchorSelect={'#i'+row.indicatorId}
                events={['click', 'touchend']}
                opacity={1}
                 style={{backgroundColor: '#5b1a1a', opacity : 'revert !important', zIndex : '99999'}}
                 html={`
                 <b>Ficha T&eacute;cnica</b>
                    <ul>
                        <li>Nombre: ${row.name}</li>
                        <li>Clave: ${row.key}</li>
                        <li>Unidad: ${row.unitLarge == null ? '' : row.unitLarge}</li>
                        <li>Fuente: ${row.sourceLarge == null ? '' : row.sourceLarge}</li>
                        <li>Periodicidad: Mensual</li>
                        <li>Periodo: ${row.periodRange == null ? '' : row.periodRange.periodLabel}</li>
                        <li>${row.notes == null ?  '' : 'Notas: ' + row.notes}</li>
                    </ul>
                 `}/>
            </div> ,
        },
        {
            name: back(),
            selector: row => '',
            maxWidth: '2px',
            minWidth: '50px',
        },
        {
            name: monthlyLabes[5] ? <b>{`${monthlyLabes[5]}`}</b> : '',
            selector: row => renderColumnData(row, 5),
            maxWidth: '5px',
        },
        {
            name: monthlyLabes[4] ? <b>{`${monthlyLabes[4]}`}</b> : '',
            selector: row => renderColumnData(row, 4),
            maxWidth: '5px',
        },
        {
            name: monthlyLabes[3] ? <b>{`${monthlyLabes[3]}`}</b> : '',
            selector: row => renderColumnData(row, 3),
            maxWidth: '5px',
        },
        {
            name: monthlyLabes[2] ? <b>{`${monthlyLabes[2]}`}</b> : '',
            selector: row => renderColumnData(row, 2),
            maxWidth: '5px',
        },
        {
            name: monthlyLabes[1] ? <b>{`${monthlyLabes[1]}`}</b> : '',
            selector: row => renderColumnData(row, 1),
            maxWidth: '5px',
        },
        {
            name: monthlyLabes[0] ? <b>{`${monthlyLabes[0]}`}</b> : '',
            selector: row => renderColumnData(row, 0),
            maxWidth: '5px',
        },
        {
            name: next(),
            selector: row => '',
            maxWidth: '2px',
            minWidth: '50px',
        },]);
    }, [monthlyLabes]);

    useEffect(() => {
        setLabelQuarter([
            {
                name: '',
                selector: row => 
                    row.key != "" && row.visible
                        ? 
                            <a href='#' onClick={()=>
                                {
                                    setNewIndicatorQuaterly(row.indicatorId);
                                    setShowChart2(true);
                                }
                            }>
                                <i class="material-icons">add_chart</i>
                            </a> 
                        : 
                            "",
                width:  '60px',
            },
        {
            name: '',
            selector: row => row.key,
            width:  '90px'
        },
        {
            name: <b>Trimestrales</b>,
            width:  '404px',
            selector: row => row.key == "" ? <b>{row.name}</b> :
            <div style={{cursor: 'pointer'}} id={'i3'+row.indicatorId} >{row.name}<br/><div className='table-unit'>{row.unit}</div> 
            <ReactTool.Tooltip anchorSelect={'#i3'+row.indicatorId}
                events={['click', 'touchend']}
                opacity={1}
                 style={{backgroundColor: '#5b1a1a', zIndex : '99999'}}
                 html={`
                 <b>Ficha T&eacute;cnica</b>
                    <ul>
                        <li>Nombre: ${row.name}</li>
                        <li>Clave: ${row.key}</li>
                        <li>Unidad: ${row.unitLarge == null ? '' : row.unitLarge}</li>
                        <li>Fuente: ${row.sourceLarge == null ? '' : row.sourceLarge}</li>
                        <li>Periodicidad: Trimestral</li>
                        <li>Periodo: ${row.periodRange == null ? '' : row.periodRange.periodLabel}</li>
                        <li>${row.notes == null ?  '' : 'Notas: ' + row.notes}</li>
                    </ul>
                 `}/>
            </div> ,
        },
        {
            name: backQuarter(),
            selector: row => '',
            maxWidth: '2px',
            minWidth: '50px',
        },
        {
            name: quartersLabes?.length >= 6 && quartersLabes[5] ? <b>{`${quartersLabes[5]}`}</b> : '',
            selector: row => renderColumnData(row, 5),
            maxWidth: '5px',
        },
        {
            name: quartersLabes?.length >= 5 && quartersLabes[4] ? <b>{`${quartersLabes[4]}`}</b> : '',
            selector: row => renderColumnData(row, 4),
            maxWidth: '5px',
        },
        {
            name: quartersLabes?.length >= 4 && quartersLabes[3] ? <b>{`${quartersLabes[3]}`}</b> : '',
            selector: row => renderColumnData(row, 3),
            maxWidth: '5px',
        },
        {
            name: quartersLabes?.length >= 3 && quartersLabes[2] ? <b>{`${quartersLabes[2]}`}</b> : '',
            selector: row => renderColumnData(row, 2),
            maxWidth: '5px',
        },
        {
            name: quartersLabes?.length >= 2 && quartersLabes[1] ? <b>{`${quartersLabes[1]}`}</b> : '',
            selector: row => renderColumnData(row, 1),
            maxWidth: '5px',
        },
        {
            name: quartersLabes?.length >= 1 && quartersLabes[0] ? <b>{`${quartersLabes[0]}`}</b> : '',
            selector: row => renderColumnData(row, 0),
            maxWidth: '5px',
        },
        {
            name: nextQuarter(),
            selector: row => '',
            maxWidth: '2px',
            minWidth: '50px',
        },]);
    }, [quartersLabes]);

    const renderColumnData = (row, index) =>{
        if(row.data && row.visible){
            if(row.data[index]?.value == null){
                return <div></div>
            }
            if(row.data[index]?.value == -9999){
                return <img height={'35px;'} src={require('../images/blurred_text.png')} />
            }else{
                if(row.unit == 'Porcentaje'){
                    return <CurrencyFormat value={row.data[index]?.value} displayType={'text'} /*suffix={'%'}*//>
                }else{
                    return <CurrencyFormat value={row.data[index]?.value} displayType={'text'} thousandSeparator={true}/>
                }
                
            }
        }else{
            if(row.data!=null  && row.data[index]!=null && row.data[index]?.value == -9999){
                return <img height={'35px;'} src={require('../images/blurred_text.png')} />
            }else{
                return ''
            }        
        }
    }
    var retry = true;
    const getGeneralIndicators = async () => {
        console.log('getGeneralIndicators');
        try{
            const response = await api.getGeneralIndicatorsCall();
            console.log('response', response);
            if(response && response.responseCode === 0){
                console.log('responseMessage ::', response.responseMessage)
                setMainTabInfo(response.obj);
            }
        }catch(error){
            console.log('error', error);
            if(retry){
                retry = false;
                getGeneralIndicators();
            }
        }
        
    }

    const getSubIndicatorsByGeneralIndicator = async (generalIndicatorId) => {
        console.log('getSubIndicatorsByGeneralIndicator');
        const response = await api.getSubIndicatorsByGeneralIndicatorIdCall(generalIndicatorId);
        console.log('response', response);
        if(response && response.responseCode === 0){
            console.log('responseMessage ::', response.responseMessage)
            setSubTabInfo(response.obj);
        }
    }

    const handleSelectSubIndicator = (eventKey) => {
        console.log(`selected ${eventKey}`);
        setTableData([]);
        setQuartersTableData([]);
        setSubIndicatorId(eventKey);
        setPage(0);
        setPageQuarter(0);
    };

    const getIndicatorsBySubIndicator = async () => {
        console.log('getIndicatorsBySubIndicator');
        console.log('Page :: ', page);
        setLoading(true);
        console.log('subIndicatorId :: ', subIndicatorId);
        const response = await api.getIndicatorsBySubIndicatorIdCall(subIndicatorId, 1, page);
        setLoading(false);
        console.log('response', response);
        if(response && response.responseCode === 0){
            console.log('responseMessage ::', response.responseMessage)
            //Search for the indicator with more labels
            let maxLabelsIndex = -1;
            let maxLabelsCount = 0;

            response.obj.forEach((item, index) => {
                if (item?.labels && item.labels?.length > maxLabelsCount) {
                    maxLabelsCount = item.labels.length;
                    maxLabelsIndex = index;
                }
            });
            let _monthlyLabels = [];
            if(maxLabelsIndex != -1){
                _monthlyLabels = response.obj[maxLabelsIndex]?.labels;
                setMonthlyLabels(_monthlyLabels);
            }
            let _tableData = response.obj;
            console.log('PREV DATA');
            console.log({_tableData});
            //Reorder values to match the labels
            _tableData.forEach((item, index) => {
                console.log('_tableData[index].key :: ' + _tableData[index].key);
                console.log('_monthlyLabels.length :: ' + _monthlyLabels.length);
                //To test 1 Key
                //if(_tableData[index].key == 'LRM0009'){
                    for(let i = 0; i < _monthlyLabels.length; i++){
                        //console.log('i :: ' + i);
                        //console.log(_tableData[index].labels[i]);
                        //console.log(_monthlyLabels[i]);
                        if(_tableData[index].labels[i] != _monthlyLabels[i]){
                            _tableData[index].labels.splice(i, 0, '');//_monthlyLabels[i]
                            _tableData[index].data.splice(i, 0, {value : ''});
                        }
                    }
                //}
                
            });
            console.log({_tableData});
            setTableData(_tableData);


        }
    }

    const getIndicatorsBySubIndicatorByQuarters = async () => {
        console.log('getIndicatorsBySubIndicatorByQuarters');
        console.log('PageQuarter :: ', pageQuarter);
        const responseQuarters = await api.getIndicatorsBySubIndicatorIdCall(subIndicatorId, 2, pageQuarter);
        if(responseQuarters && responseQuarters.responseCode === 0){
            console.log('responseMessage ::', responseQuarters.responseMessage)
            
            if(responseQuarters.obj != null && responseQuarters.obj.length > 0){

                //Search for the indicator with more labels
                let maxLabelsIndex = -1;
                let maxLabelsCount = 0;

                responseQuarters.obj.forEach((item, index) => {
                    if (item?.labels && item.labels?.length > maxLabelsCount) {
                        maxLabelsCount = item.labels.length;
                        maxLabelsIndex = index;
                    }
                });

                let _quarterLabels = [];
                if(maxLabelsIndex != -1){
                    _quarterLabels = responseQuarters.obj[maxLabelsIndex]?.labels;
                    setQuartersLabels(_quarterLabels);
                }

                let _tableData = responseQuarters.obj;
                console.log('PREV DATA');
                console.log({_tableData});
                //Reorder values to match the labels
                _tableData.forEach((item, index) => {
                    console.log('_tableData[index].key :: ' + _tableData[index].key);
                    console.log('_quarterLabels.length :: ' + _quarterLabels.length);
                    //To test 1 Key
                    //if(_tableData[index].key == 'LRM0009'){
                        for(let i = 0; i < _quarterLabels.length; i++){
                            //console.log('i :: ' + i);
                            //console.log(_tableData[index].labels[i]);
                            //console.log(_monthlyLabels[i]);
                            if(_tableData[index].labels[i] != _quarterLabels[i]){
                                _tableData[index].labels.splice(i, 0, '');//_monthlyLabels[i]
                                _tableData[index].data.splice(i, 0, {value : ''});
                            }
                        }
                    //}
                    
                });
                setQuartersTableData(responseQuarters.obj);
            }
        }
    }

    const changeChar1tDataToYears = async (years) => {
        console.log('changeChar1tDataToYears');
        console.log('years :: ', years);
        let ids = dataLine.ids;
        ids.sort();
        await removeDataLine();
        setTimeout(() => {
            ids.forEach(async (id, index) => {
                console.log('index :: ', index);
                await addToChart(id, true, index);
            });
        }, 100);
    }

    const changeChar2tDataToYears = async (years) => {
        console.log('changeChar2tDataToYears');
        console.log('years :: ', years);
        let ids = dataLineQuarter.ids;
        ids.sort();
        await removeDataLineQuarter();
        setTimeout(() => {
            ids.forEach(async (id, index) => {
                console.log('index :: ', index);
                await addToChartQuaterly(id, true, index);
            });
        }, 100);        
    }
    


    return (
        <>
        <Container 
        style={{'min-height' : '75vh'}}
        >
            <Loading loading={loading} background="transparent" loaderColor="#0f5f72" />
            <Row>
                <Col>
                    &nbsp;
                </Col>
            </Row>
            <Row>
                <Col>
                    <MainTabIndicators handleSelected={handleSelectGeneralIndicator} tabs={mainTabInfo} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <SubIndicatorsAccordion tableData={tableData} tableColumns={labels}
                        quartersTableData={quartersTableData} quartersTableColumns={labelsQuarter}  
                        handleSelected={handleSelectSubIndicator} data={subTabInfo}/>
                </Col>
            </Row>

            {
                <Modal show={showMaxIndicators} size="sm" onHide={()=>setShowMaxIndicators(false)}>
                    <Modal.Header>
                        <h3 className='text-center max-width'>No se pueden mostrar m&aacute;s Indicadores.</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='text-center'>
                            <Button onClick={()=>setShowMaxIndicators(false)} className='limit-button'>Aceptar</Button>
                        </div>
                    </Modal.Body>
                </Modal>
            }
            {
                <Modal show={showNoMoreIndicators} size="sm" onHide={()=>setShowNoMoreIndicators(false)}>
                    <Modal.Header>
                        <h3 className='text-center max-width'>No hay mas datos para este indicador.</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='text-center'>
                            <Button onClick={()=>setShowNoMoreIndicators(false)} className='limit-button'>Aceptar</Button>
                        </div>
                    </Modal.Body>
                </Modal>
            }
            {
               
                <>
                <br/>
                <br/>
                <br/>
                <Modal show={showChart1} size="xl" onHide={()=>setShowChart1(false)}>
                    <Modal.Header closeButton>
                            <h3 className='text-center max-width'>Gr&aacute;fica de Indicadores Mensuales</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <div ref={chart1Ref}>
                            
                            {
                                dataLine != null && dataLine.ids != null && dataLine.ids.length > 0 
                                ?
                                    <Row>
                                        <Col lg={9}>
                                            <Button onClick={()=>setYearsChart1(1)} className={yearsChart1 == 1 ? 'year-button-enabled' : 'year-button'}>1 año</Button>
                                            <Button onClick={()=>setYearsChart1(3)} className={yearsChart1 == 3 ? 'year-button-enabled' : 'year-button'}>3 años</Button>
                                            <Button onClick={()=>setYearsChart1(5)} className={yearsChart1 == 5 ? 'year-button-enabled' : 'year-button'}>5 años</Button>
                                            <Button onClick={()=>setYearsChart1(50)} className={yearsChart1 == 50 ? 'year-button-enabled' : 'year-button'}>Todo</Button>
                                            {
                                                //<Button onClick={()=>setYearsChart1(0)} className={yearsChart1 == 0 ? 'year-button-enabled' : 'year-button'}>Todo</Button>
                                            }
                                            
                                        </Col>
                                        <Col>
                                        &nbsp;
                                        </Col>
                                        <Col>
                                        <Button onClick={()=>{
                                                        setNewIndicator(-1);
                                                        removeDataLine();
                                                        setShowChart1(false);
                                                    }
                                                } 
                                                className={'year-button'}>Limpiar</Button>
                                        </Col>
                                    </Row>
                                :
                                    <></>
                            }
                            {
                                dataLine != null && dataLine.ids != null && dataLine.ids.length > 0 
                                ?
                                    <Row>
                                        <Col>
                                            <Line options={chartOptions} data={dataLine} />
                                        </Col>
                                    </Row>
                                :
                                    <></>
                            }
                            
                            
                        </div>
                </Modal.Body>
                </Modal>
                <>
                    <br/>
                    <br/>
                    <br/>
                </>
                <Modal show={showChart2} size="xl" onHide={()=>setShowChart2(false)}>
                    <Modal.Header closeButton>
                        <h3 className='text-center max-width'>Gr&aacute;fica de Indicadores Trimestrales</h3>
                    </Modal.Header>
                    <Modal.Body>
                    
                        <div ref={chart2Ref}>
                            {
                                dataLineQuarter != null && dataLineQuarter.ids != null && dataLineQuarter.ids.length > 0 
                                ?
                                    <Row>
                                        <Col lg={9}>
                                            <Button onClick={()=>setYearsChart2(1)} className={yearsChart2 == 1 ? 'year-button-enabled' : 'year-button'}>1 año</Button>
                                            <Button onClick={()=>setYearsChart2(3)} className={yearsChart2 == 3 ? 'year-button-enabled' : 'year-button'}>3 años</Button>
                                            <Button onClick={()=>setYearsChart2(5)} className={yearsChart2 == 5 ? 'year-button-enabled' : 'year-button'}>5 años</Button>
                                            <Button onClick={()=>setYearsChart2(50)} className={yearsChart2 == 50 ? 'year-button-enabled' : 'year-button'}>Todo</Button>
                                            {
                                                //<Button onClick={()=>setYearsChart2(0)} className={yearsChart2 == 0 ? 'year-button-enabled' : 'year-button'}>Todo</Button>
                                            }
                                        </Col>
                                        <Col>
                                            &nbsp;
                                        </Col>
                                        <Col>
                                            <Button onClick={()=>{
                                                        setNewIndicatorQuaterly(-1);
                                                        removeDataLineQuarter();
                                                        setShowChart2(false);
                                                    }
                                                } 
                                                className={'year-button'}>Limpiar</Button>
                                        </Col>
                                    </Row>
                                :
                                    <></>
                            }
                            {
                                dataLineQuarter != null && dataLineQuarter.ids != null && dataLineQuarter.ids.length > 0 
                                ?
                                    <Row>
                                        <Col>
                                            <Line options={chartOptionsPercent} data={dataLineQuarter} />
                                        </Col>
                                    </Row>
                                :
                                    <></>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
                </>
                
                
            }
            
        </Container>
        {
            <>
            {props.children}
            </>
        }
        </>
    );
};

export default Home;