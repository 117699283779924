import React from 'react';
import Nav from 'react-bootstrap/Nav';
import * as ReactTool from 'react-tooltip';

function MainTabIndicators(props){
    
    return(
        <>
            <Nav variant="tabs" defaultActiveKey={1} onSelect={props.handleSelected}>
                {
                    props.tabs != null && props.tabs.map((tab, index) => {
                        console.log(tab.name);
                        return(
                            <Nav.Item key={tab.generalIndicatorId}>
                                <Nav.Link   eventKey={tab.generalIndicatorId} ><div id={'tab'+tab.generalIndicatorId}>{tab.name}</div>
                                <ReactTool.Tooltip anchorSelect={'#tab'+tab.generalIndicatorId}
                                        opacity={1}
                                        style={{backgroundColor: '#5b1a1a', opacity : '1', zIndex : '99999'}}
                                        html={`
                                            <ul>
                                                <li>${tab.description}</li>
                                            </ul>
                                        `}/>
                                </Nav.Link>
                            </Nav.Item>
                        );
                    })
                }
            </Nav>
        </>
    )
}

export default MainTabIndicators;